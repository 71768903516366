import { useState, CSSProperties } from "react";
import { usePopper } from "react-popper";

export function useCustomPopper<
  TReferenceElement extends HTMLElement,
  TPopperElement extends HTMLElement
>(options?: Record<string, unknown>) {
  const [referenceElement, setReferenceElement] =
    useState<TReferenceElement | null>(null);
  const [popperElement, setPopperElement] = useState<TPopperElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      ...(options ?? {}),
    }
  );

  const reference: {
    ref: typeof setReferenceElement;
  } = {
    ref: setReferenceElement,
  };

  const fixedPopper: {
    ref: typeof setPopperElement;
    style: CSSProperties;
  } = {
    ref: setPopperElement,
    style: {
      ...styles.popper,
      zIndex: 2,
    },
    ...attributes.popper,
  };

  return {
    reference,
    fixed: fixedPopper,
    update,
  };
}

export type CustomPopperReturnType = ReturnType<typeof useCustomPopper>;
