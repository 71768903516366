import { IGridSettings } from './types';

export const TABLE_ATTRIBUTES = Object.freeze({
  horizontalPadding: 16,
  hasBorderClassName: 'with-border',
  firstColumnDataAttribute: 'data-is-first-column',
  lastStaticColumnDataAttribute: 'data-is-last-static-column',
  movingFooterViewType: 'multigrid-moving-footer',
  borderViewType: 'absolute-border',
});

export const GRID_SETTINGS: IGridSettings = Object.freeze({
  headerHeight: 48,
  cellDataHeight: 80,
  footerHeight: 48,
  shouldShowFooter: false,
  isFooterInRelative: false,
  shouldConsiderHeight: true,
  shouldOnlyConsiderAvailableHeight: false,
  getComputedRowHeight: () => 0,
  getAvailableHeight: () => null,
});
