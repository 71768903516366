import styled, { css } from "styled-components";
import { TABLE_ATTRIBUTES } from "../constants";

export const Wrapper = styled.div<{ isActive: boolean; canHover?: boolean }>`
  padding: 12px ${TABLE_ATTRIBUTES.horizontalPadding}px;
  background-color: ${(props) =>
    props.isActive ? "var(--white)" : "var(--wash-me)"};

  &:hover {
    ${(props) => props.canHover && "background-color: var(--white)"};
  }
`;

export const IconWrapper = styled.div<{ isActive?: boolean }>`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--wash-me);
      box-shadow: 0 1px 2px rgba(12, 16, 33, 0.05), 0 0 0 4px var(--athens-gray);
    `}

  &:hover {
    background-color: var(--wash-me);
  }
`;

export const AllOptions = styled.div`
  max-height: 320px;
  box-shadow: 0 32px 64px -12px rgba(12, 16, 33, 0.14);
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  background-color: var(--white);
`;

export const EachOption = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--rhino);
  padding: 16px;
  border-radius: inherit;
  &:hover {
    background-color: var(--wash-me);
  }
`;
