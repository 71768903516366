/* eslint-disable no-param-reassign */
import { Options } from '@popperjs/core';

export const SAME_WIDTH_MODIFIER: Options['modifiers'][0] = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.elements.popper.style.top = '1px';
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.top = '1px';
    if ('offsetWidth' in state.elements.reference) {
      // @ts-ignore
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    }
  },
};
