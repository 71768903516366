import styled, { css } from 'styled-components';
import { TABLE_ATTRIBUTES } from './constants';

export const ListWrapper = styled.div`
  .ReactVirtualized__Grid:focus {
    outline: none;
  }

  .ReactVirtualized__Grid {
    overflow-y: visible;
  }

  .ReactVirtualized__Table__headerRow {
    font-weight: initial;
    text-transform: initial;
  }

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    margin: 0;
    height: 100%;
  }

  div,
  p,
  a {
    &[data-text-type='main-text'],
    &[data-text-type='main-text-light'] {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: var(--rhino);
    }

    &[data-text-type='sub-text'],
    &[data-text-type='sub-text-light'] {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: var(--lynch);
      margin-top: 2px;
    }

    &[data-text-type='main-text-light'],
    &[data-text-type='sub-text-light'] {
      color: var(--manatee);
    }
  }

  .tooltip-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AbsoluteBorder = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  box-shadow: 0 4px 8px -2px rgba(12, 16, 33, 0.1),
    0 2px 4px -2px rgba(12, 16, 33, 0.06);
  background-color: var(--athens-gray);
`;

const Cell = styled.div`
  &[${TABLE_ATTRIBUTES.lastStaticColumnDataAttribute}='true'] {
    position: relative;

    &.${TABLE_ATTRIBUTES.hasBorderClassName} ${AbsoluteBorder} {
      display: block;
    }
  }
`;

export const HeaderCell = styled(Cell)<{
  columnIndexType: 'first' | 'last' | null;
}>`
  border-top: 1px solid var(--athens-gray);
  border-bottom: 1px solid var(--athens-gray);
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);

  ${props =>
    props.columnIndexType === 'first' &&
    css`
      border-left: 1px solid var(--athens-gray);
      border-top-left-radius: 8px;
    `}

  ${props =>
    props.columnIndexType === 'last' &&
    css`
      border-right: 1px solid var(--athens-gray);
      border-top-right-radius: 8px;
    `}

  &:hover {
    background-color: var(--white);
  }
`;

const PADDING_STYLES = Object.freeze({
  first_child: css`
    > *:not([data-view-type='${TABLE_ATTRIBUTES.borderViewType}']) {
      padding: 16px ${TABLE_ATTRIBUTES.horizontalPadding}px;
    }
  `,
  self: css`
    padding: 16px ${TABLE_ATTRIBUTES.horizontalPadding}px;
  `,
  'horizontal-child': css`
    > * {
      padding: 0 ${TABLE_ATTRIBUTES.horizontalPadding}px;
    }
  `,
  none: css``,
});

export const BodyCell = styled(Cell)<{
  rowIndexType: 'first' | 'last' | null;
  columnIndexType: 'first' | 'last' | null;
  paddingLayer: 'self' | 'first_child' | 'horizontal-child' | 'none';
}>`
  border-bottom: 1px solid var(--athens-gray);

  ${props =>
    props.columnIndexType === 'first' &&
    css`
      border-left: 1px solid var(--athens-gray);
    `}

  ${props =>
    props.columnIndexType === 'last' &&
    css`
      border-right: 1px solid var(--athens-gray);
    `}

  ${props => PADDING_STYLES[props.paddingLayer]}

  ${props =>
    props.rowIndexType === 'last' &&
    props.columnIndexType === 'first' &&
    css`
      border-bottom-left-radius: 4px;
    `};

  ${props =>
    props.rowIndexType === 'last' &&
    props.columnIndexType === 'last' &&
    css`
      border-bottom-right-radius: 4px;
    `};
`;
