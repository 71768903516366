import { useState, useCallback } from 'react';

type InitialStateType = boolean | (() => boolean);

export interface IBooleanActions {
  on: () => void;
  off: () => void;
  toggle: () => void;
}

function useBoolean(initialState: InitialStateType = false) {
  const [isEnabled, setEnabled] = useState(initialState);

  const on = useCallback(() => {
    setEnabled(true);
  }, []);

  const off = useCallback(() => {
    setEnabled(false);
  }, []);

  const toggle = useCallback(() => {
    setEnabled(prev => !prev);
  }, []);

  return [isEnabled, { on, off, toggle, redirectTo: setEnabled }] as const;
}

export default useBoolean;
