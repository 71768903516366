import { DependencyList, useEffect, useRef } from "react";

function debounce(func: Function, duration: number) {
  let timeout: NodeJS.Timeout | null;

  function clear() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  function implementation(...args: Array<unknown>) {
    clear();

    timeout = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
      clear();
    }, duration);
  }

  return {
    clear,
    implementation,
  };
}

function useDebounce(
  callback: Function,
  duration: number,
  deps?: DependencyList
) {
  const debounceRef = useRef(debounce(callback, duration));

  useEffect(() => {
    debounceRef.current = debounce(callback, duration);
    return () => {
      debounceRef.current.clear();
    };
  }, [duration, ...(deps ?? [])]);

  return debounceRef.current;
}

export default useDebounce;
