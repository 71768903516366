import { IconPropsType } from "./types";
import * as React from "react";

interface INormalTnaIconProps extends IconPropsType {}

function NoInternetIcon(props: INormalTnaIconProps) {
  return (
    <svg
      width={261}
      height={220}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_1_53)">
        <path
          d="M9.081 111.309c15.736-12.373 40.485-1.858 57.954-11.645 10.589-5.933 16.053-18.075 19.011-29.846 2.96-11.772 4.185-24.135 9.501-35.052 5.316-10.906 16.296-20.355 28.364-19.025 9.025.993 16.285 7.57 24.496 11.434 22.171 10.442 49.246.507 72.495 8.246 17.099 5.701 30.14 20.788 35.794 37.891 5.653 17.104 4.533 35.917-.518 53.211-9.31 31.873-32.158 59.566-61.706 74.779-31.872 16.418-70.445 18.033-104.59 7.095-19.645-6.292-37.885-16.586-53.895-29.54-12.83-10.389-29.875-24.135-34.44-40.742-2.611-9.481-.519-20.482 7.524-26.806h.01z"
          fill="#F7F8FA"
        />
        <path
          d="M29.414 91.46c5.051 1.078 10.557-.136 14.615-3.325 5.41-4.234 7.852-11.898 5.876-18.476-3.794-12.68-22.436-16.734-31.503-7.095-9.088 9.66-.793 26.395 11.012 28.897zM68.578 81.621c4.333.76 8.95-2.059 10.462-6.187 2.811-7.707-6.024-17.41-13.939-13.429-8.137 4.096-5.094 18.106 3.477 19.616z"
          fill="#F7F8FA"
        />
        <path
          d="M143.605 187.861c14.675-.505 29.277 2.693 43.951 2.104 14.675-.589 30.736-6.427 35.612-18.418 4.036-9.94-1.24-21.52-10.675-28.178-9.435-6.658-22.164-8.962-34.323-8.52-12.158.442-23.988 3.376-35.72 6.195-11.733 2.819-23.648 5.554-35.818 5.575-13.168.031-26.31-3.208-37.484-9.225-6.942-3.744-13.264-8.583-21.021-10.844-7.757-2.261-17.812-.978-21.776 5.217-4.158 6.5.633 14.978 7.733 19.164 9.362 5.512 22.553 5.628 32.049.274 13.301-7.5 17.18-22.804 17.556-36.53.377-13.727-1.446-28.084 4.876-40.685 4.948-9.876 14.869-17.797 26.65-21.278 11.782-3.482 25.229-2.451 36.122 2.745"
          stroke="#FF991F"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M200.232 19.658l-6.204 5.744M196.184 27.724l7.461.285M191.873 23.079l.285-7.464"
          stroke="#FA5762"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.787 204.427h169.085M224.781 204.427h-8.454"
          stroke="#BABFCB"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M193.077 204.596H53.582a4.223 4.223 0 01-4.227-4.223l-2.113-96.074a4.224 4.224 0 014.227-4.223H195.19a4.224 4.224 0 014.227 4.223l-2.114 96.074a4.223 4.223 0 01-4.227 4.223z"
          fill="#fff"
          stroke="#BABFCB"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M48.298 116.968h118.36M182.51 116.968h15.851M170.885 116.968h6.341"
          stroke="#BABFCB"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M55.696 110.633a2.112 2.112 0 100-4.223 2.112 2.112 0 100 4.223zM64.15 110.633a2.112 2.112 0 100-4.223 2.112 2.112 0 100 4.223zM72.604 110.633a2.112 2.112 0 100-4.223 2.112 2.112 0 100 4.223zM115.932 157.699a2.112 2.112 0 100-4.223 2.112 2.112 0 100 4.223zM128.635 157.699a2.112 2.112 0 100-4.223 2.112 2.112 0 100 4.223z"
          fill="#BABFCB"
        />
        <path
          d="M118.046 162.366s4.1-3.199 8.465 0"
          stroke="#BABFCB"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M149.55 35.067l20.072 34.154c1.458 2.48-.332 5.608-3.214 5.608h-40.145c-2.882 0-4.672-3.127-3.214-5.608l20.072-34.154c1.441-2.451 4.988-2.451 6.429 0z"
          fill="#fff"
          stroke="#BABFCB"
        />
        <path
          d="M143.297 67.07c0-1.985 1.363-3.368 3.308-3.368 1.944 0 3.276 1.383 3.297 3.368 0 1.922-1.3 3.336-3.297 3.336s-3.308-1.425-3.308-3.336zm1.173-5.638l-.771-15.847a.444.444 0 01.444-.464h4.914c.253 0 .465.21.444.464l-.772 15.847a.441.441 0 01-.444.422h-3.371a.443.443 0 01-.444-.422z"
          fill="#FF991F"
        />
        <path
          d="M244.627 17.103a4.225 4.225 0 004.227-4.223 4.225 4.225 0 00-4.227-4.223 4.225 4.225 0 00-4.227 4.223 4.225 4.225 0 004.227 4.223z"
          fill="#E8EAEF"
        />
        <path
          d="M248.653 37.332l-8.063-4.646v9.302l8.063-4.656z"
          stroke="#E8EAEF"
          strokeWidth={0.66}
          strokeMiterlimit={10}
        />
        <path d="M248.823 29.118v-8.446h-8.455v8.446h8.455z" fill="#E8EAEF" />
        <path
          d="M227.793 209.2a2.113 2.113 0 100-4.226 2.113 2.113 0 000 4.226zM240.463 165.121v8.447M236.236 169.344h8.455"
          stroke="#FA5762"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.764 194.524a4.223 4.223 0 100-8.446 4.224 4.224 0 100 8.446z"
          fill="#E8EAEF"
        />
        <path
          d="M20.737 214.753l8.064-4.656v9.301l-8.064-4.645z"
          stroke="#E8EAEF"
          strokeWidth={0.66}
          strokeMiterlimit={10}
        />
        <path d="M29.023 206.528v-8.446h-8.455v8.446h8.454z" fill="#E8EAEF" />
        <path
          d="M41.799 8.974a4.225 4.225 0 004.227-4.223A4.225 4.225 0 0041.8.528a4.225 4.225 0 00-4.227 4.223 4.225 4.225 0 004.227 4.223zM24.774 23.406a2.113 2.113 0 10-2.113-2.111c0 1.166.946 2.111 2.113 2.111z"
          stroke="#FA5762"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1_53">
          <path fill="#fff" transform="translate(.5)" d="M0 0h260v220H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoInternetIcon;
