import styled, { css } from 'styled-components';

export const getSizeCss = (
  size: number,
  height: number | undefined,
  width: number | undefined
) => css`
  height: ${height ?? size}px;
  width: ${width ?? size}px;
  min-height: ${height ?? size}px;
  min-width: ${width ?? size}px;
`;

export const Wrapper = styled.div<{
  size: number;
  customMargin?: string;
  height?: number;
  width?: number;
  borderRadius: number;
}>`
  cursor: pointer;
  ${props => getSizeCss(props.size, props.height, props.width)};
  background: var(--pippin);
  border: 1px solid var(--athens-gray);
  border-radius: ${props => props.borderRadius}px;
  margin: ${props => props.customMargin ?? 'none'};
`;

export const Image = styled.img<{ borderRadius: number }>`
  object-fit: cover;
  border-radius: ${props => props.borderRadius}px;
`;

export const ImageViewerWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 20;
`;
