import {
  LOCAL_STORAGE_KEYS,
  LocalStorageKeyType,
  KEYS_TO_NOT_CLEAR,
} from '../constants/localStorage';
import { getObjectKeys } from '../utils';

export function getFromStorage(key: LocalStorageKeyType): string | null {
  return localStorage.getItem(LOCAL_STORAGE_KEYS[key]);
}

export function setInStorage(key: LocalStorageKeyType, value: string) {
  localStorage.setItem(LOCAL_STORAGE_KEYS[key], value);
}

export function removeItemFromStorage(key: LocalStorageKeyType) {
  localStorage.removeItem(LOCAL_STORAGE_KEYS[key]);
}

export function clearStorages() {
  getObjectKeys(LOCAL_STORAGE_KEYS).forEach(keyName => {
    if (!KEYS_TO_NOT_CLEAR.includes(keyName)) {
      removeItemFromStorage(keyName);
    }
  });
}
