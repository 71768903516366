import styled from 'styled-components';

export const Dot = styled.div<{ fill: string }>`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 8px;
  background-color: ${props => props.fill};
  margin-bottom: 16px;
`;

export const Label = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--fiord);
  margin-right: 12px;
  margin-bottom: 16px;
`;
