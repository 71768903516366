import { useLayoutEffect, useRef, useState, DependencyList } from 'react';
import { IGridSettings } from './types';

export function useTableHeight(
  listLength: number,
  dependencies: DependencyList,
  gridSettings: IGridSettings
) {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [cellsHeight, setCellsHeight] = useState(0);
  const availableHeight =
    gridSettings.getAvailableHeight?.() ?? listRef.current?.clientHeight ?? 0;
  const computedRowHeight = gridSettings.getComputedRowHeight();

  function calculcateTableHeight() {
    const totalRowHeight =
      gridSettings.headerHeight + gridSettings.cellDataHeight * listLength;

    let calculatedHeight = totalRowHeight + computedRowHeight;
    if (gridSettings.shouldConsiderHeight) {
      if (availableHeight !== 0) {
        calculatedHeight = Math.min(availableHeight, calculatedHeight);
      }
    } else if (gridSettings.shouldOnlyConsiderAvailableHeight) {
      calculatedHeight =
        availableHeight === 0 ? calculatedHeight : availableHeight;
    }

    return {
      calculatedTableHeight:
        calculatedHeight >= availableHeight && gridSettings.shouldShowFooter
          ? calculatedHeight +
            (gridSettings.isFooterInRelative ? 1 : -1) *
              gridSettings.footerHeight
          : calculatedHeight,
      calculatedCellsHeight: calculatedHeight,
    };
  }

  useLayoutEffect(() => {
    const {
      calculatedTableHeight,
      calculatedCellsHeight,
    } = calculcateTableHeight();
    setTableHeight(calculatedTableHeight);
    setCellsHeight(calculatedCellsHeight);
  }, [listLength, availableHeight, computedRowHeight, ...dependencies]);

  return {
    listRef,
    tableHeight,
    cellsHeight,
  };
}
