import { useRef } from 'react';

export const usePaginatedTable = fetchNext => {
  const observer = useRef(null);
  const lastItemRef = node => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchNext();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  };

  return lastItemRef;
};
